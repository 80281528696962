.registerform {
    display: flex;
    justify-content: center;
}

.registerform_wrapper {
    max-width: 35.63rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .emoji {
        width: 40px;
        height: 40px;
    }

    .content {
        .title {
            margin-bottom: .3rem;
        }
        
        .description {
            color: var(--colors_neutral-3)
        }

        .existing_description {
            color: var(--colors_neutral-3)
        }

        .existing_label {
            color: var(--colors_primary);
            font-weight: 700;
            cursor: pointer;
        }
 
    }    
}

.actions {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--colors_neutral-5);
    padding-top: 1rem;

    > div {
        transition-duration: 0s;
    }

    .action_content {
        display: flex;
        align-items: center;

        svg {
            height: 1.2rem;
            margin-right: .5rem;
        }
    }

    .action_content_prev {
        @extend .action_content;
        color: white;

        svg {
            margin-right: 0;
            margin-left: .5rem;
        }
    }
}

.form_step {
    margin: 4rem 0 6rem 0;

    .label {
        font-size: var(--fontsize_s);
        font-weight: 500;
        color: var(--colors_neutral-1);
        margin-bottom: .3rem;
    }

    .label_repeat {
        margin-top: .75rem;
    }
}

@keyframes animateIn {
    0% {
        opacity: 0;
        left: calc(100% + 0.3rem);
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@keyframes animateOut {
    0% {
        opacity: 1;
        left: 0;
        visibility: visible;
    }

    100% {
        opacity: 0;
        left: -5rem;
        visibility: hidden;
    }
}

.form {
    position: relative;
    height: 20rem;
    clip-path: inset(0rem -0.2rem 0rem -1rem);

    .form_step {
        width: 100%;
        position: absolute;
        top: 0;
        left: calc(100% + 0.3rem);
    }

    .form_step_in {
        animation: animateIn .3s ease forwards;
        animation-delay: .3s;
    }

    .form_step_out {
        animation: animateOut .3s ease forwards;
    }
}