.groupdropdown {
    position: relative;

    .input_ct {   
        color: var(--input_primaryText);
        display: flex;
        align-items: center;
        
        transition: all var(--transitions_easeoutexpo) var(--transitions_medium);
        position: relative;

        .input {
            border: none;
            padding: 0.75rem;
            height: 100%;
            width: 100%;
            background-color: transparent;
            font-family: var(--fonts_primary);
            font-size: 1rem;

            &:focus {
                outline: none;
            }
        }

        .arrow {
            padding: 0 .75rem;
            svg {
                width: 1rem;
            }
        }

        &:has(.input:focus) {
            outline: none;
            background-color: var(--input_focusFill);
            box-shadow: 0 0 0 2px var(--input_focusBorder);
        }
    }

    .list {
        z-index: 1;
        position: absolute;
        background-color: white;
        left: 0;
        top: calc(100% + 5px);
        width: 100%;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        max-height: 15rem;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        transition: .3s ease all;

        .sublist {
            display: flex;
            flex-direction: column;
        }

        .parent_option {
            font-size: 1rem;
            font-family: var(--fonts_primary);
            font-weight: bold;
            text-align: left;
            padding: .75rem;

            &:hover {
                background-color: var(--colors_neutral-6);
            }
        }

        .option {
            font-size: 1rem;
            font-family: var(--fonts_primary);
            text-align: left;
            padding: .75rem;
            padding-left: 1.5rem;

            &:hover {
                background-color: var(--colors_neutral-6);
            }
        }
    }

    .list_hidden {
        max-height: 0px;
    }
}