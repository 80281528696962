.management {
    padding: 2rem;
    margin-right: 100px;

    .table {
        
        background-color: white;
        border-radius: 10px;

        .header {
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid var(--colors_neutral-5);
            flex-wrap: wrap;
            row-gap: 1rem;

            .search_icon {
                height: 1rem;
            }
        }
    }
}

.tasks {
    position: fixed;
    width: 100px;
    right: 0;
    top: 40px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding-top: 5rem;

    .task {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;

        .icon {
            min-width: 45px;
            min-height: 45px;
            max-width: 45px;
            max-height: 45px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease;

            img {
                width: 1.3rem;
            }
        }
        
        .label {
            margin-top: 1.2rem;
            font-size: 1rem;
            font-weight: 500;
            line-height: 100%;
        }
    }

    .task_green {
        .icon {
            background-color: #0EBC93;
            box-shadow: 0 0 0 10px #0ebc9325;
        }
        &:hover {
            .icon {
                box-shadow: 0 0 0 15px #0ebc9346;
            }
        }
    }

    .task_orange {
        .icon {
            background-color: #BC810E;
            box-shadow: 0 0 0 10px #BC810E25;
        }
        &:hover {
            .icon {
                box-shadow: 0 0 0 15px #BC810E46;
            }
        }
    }

    .task_red {
        .icon {
            background-color: #bc0e0e;
            box-shadow: 0 0 0 10px #bc0e0e25;
        }
        &:hover {
            .icon {
                box-shadow: 0 0 0 15px #bc0e0e46;
            }
        }
    }

    .task_purple {
        .icon {
            background-color: #A40EBC;
            box-shadow: 0 0 0 10px #A40EBC25;
        }
        &:hover {
            .icon {
                box-shadow: 0 0 0 15px #A40EBC46;
            }
        }
    }

    .task_blue {
        .icon {
            background-color: #0E68BC;
            box-shadow: 0 0 0 10px #0E68BC25;
        }
        &:hover {
            .icon {
                box-shadow: 0 0 0 15px #0E68BC46;
            }
        }
    }
}

.mobile_tasks_button {
    display: none;
}

.detail {
    .header {
        background-color: #F8F9FC;
        display: flex;
        flex-direction: column;
        padding: 4vh 4vw;
        min-width: 50vw;

        .title {
            font-size: 23px;
            font-weight: 600;
        }

        .description {
            font-size: 15px;
            color: var(--colors_neutral-3);
        }
    }

    .body {
        padding: 2rem 4vw;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .actions {
            display: flex;
            justify-content: flex-end;
        }

        .input {
            position: relative;

            .label {
                position: absolute;
                font-size: 12px;
                top: 0rem;
                transform: translateY(-100%);
                left: 0.5rem;
                font-weight: 500;
                color: var(--input_labelColor);
            }
        }
    }
}

.alert {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        background-color: var(--colors_neutral-6);
    }

    .title {
        font-weight: 500;
        font-size: 23px;
        margin-top: 1rem;
        margin-bottom: .5rem;
    }

    .description {
        color: var(--colors_neutral-3);

        .columnname {
            font-weight: 500;
            color: var(--colors_neutral-2)
        }
    }

    .actions {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.subnavigation {
    position: fixed;
    top: 100vh;
    transform: translate(-50%, -100%);
    left: 50%;
    background-color: white;
    box-shadow: 0 3px 11px #0000001c;
    padding: .5rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    display: flex;
    gap: 1rem;

    .nav_button {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
        max-width: 6rem;
        opacity: .4;

        &:hover:not(.nav_button_active) {
            opacity: .75;
        }

        .nav_icon {
            width: 1.5rem;
        }

        .nav_label {
            font-family: var(--fonts_primary);
            font-weight: 500;
        }
    }

    .nav_button_active {
        opacity: 1;
    }
}

.quill {
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ccc;
}

@media screen and (max-width: 641px) {
    .tasks {
        height: unset;
        width: unset;
        padding: 1rem;
        top: calc(100vh - 1rem);
        right: 1rem;
        transform: translateY(-100%);
        gap: 1rem;
        align-items: flex-end;
        border-radius: 10px;
        box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
        animation: showAnimation .6s var(--transitions_easeoutquint) forwards;
        opacity: 0;        

        .task {
            flex-direction: row-reverse;
            padding: 7px 7px 7px 0;
            width: fit-content;

            .icon {
                min-width: 30px;
                min-height: 30px;
                max-width: 30px;
                max-height: 30px;

                img {
                    width: 1rem;
                }
            }

            .label {
                margin-top: unset;
                margin-right: 1.2rem;
            }
        }

        .task_green {
            .icon {
                background-color: #0EBC93;
                box-shadow: 0 0 0 5px #0ebc9325;
            }
            &:hover {
                .icon {
                    box-shadow: 0 0 0 7px #0ebc9346;
                }
            }
        }
    
        .task_orange {
            .icon {
                background-color: #BC810E;
                box-shadow: 0 0 0 5px #BC810E25;
            }
            &:hover {
                .icon {
                    box-shadow: 0 0 0 7px #BC810E46;
                }
            }
        }
    
        .task_blue {
            .icon {
                background-color: #0E68BC;
                box-shadow: 0 0 0 5px #0E68BC25;
            }
            &:hover {
                .icon {
                    box-shadow: 0 0 0 7px #0E68BC46;
                }
            }
        }

        @keyframes showAnimation {
            0% {
                opacity: 0;
                visibility: hidden;
            }
    
            100% {
                opacity: 1;
                visibility: visible;
            }
        }
    
        @keyframes hideAnimation {
            0% {
                opacity: 1;
                visibility: visible;
            }
    
            100% {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .tasks_hide {
        animation: hideAnimation .3s var(--transitions_easeoutquint) forwards;
    }

    .management {
        margin-right: unset;
        padding: 1rem;
    }

    .mobile_tasks_button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: calc(1rem + 1rem + 7px);
        top: calc(100vh - 1rem - 1rem - 7px);
        transform: translateY(-100%);
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
        background-color: #0E68BC;
        box-shadow: 0 0 0 5px #0E68BC;

        &:hover {
            box-shadow: 0 0 0 7px #0E68BC46;

            img {
                transform: rotate(90deg);
            }
        }

        & img {
            width: 1.5rem;
            transition: .3s var(--transitions_easeoutquint) all;
        }
    }
}