.filedetail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;
    padding: 1rem;

    .filedetail_content {
        max-height: calc(100vh - 2rem);
        overflow-y: auto;
    }

    .header {
        display: flex;
        align-items: center;
        gap: .5rem;
        border-bottom: 1px solid var(--colors_neutral-5);
        padding-bottom: 1rem;

        .icon {
            path {
                stroke: var(--colors_neutral-4)
            }
        }

        .title {
            color: var(--colors_neutral-1);
            font-weight: 500;
            font-size: 18px;
        }
    }

    .top {
        padding: 1rem 0;
        border-bottom: 1px solid var(--colors_neutral-5);

        .image {
            padding: 2rem 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .filetype {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .filename {
            font-weight: 600;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem;
        margin-left: 1rem;

        .option {
            display: flex;
            flex-direction: column;
            gap: .25rem;

            .label {
                font-weight: 600;
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: space-evenly;
        border-top: 1px solid var(--colors_neutral-5);
        padding: 1rem 0;
        gap: 1rem;
        background: white;
        width: calc(100% - 2rem);
        min-width: fit-content;

        .option {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            &:hover {
                .icon {
                    path, polyline, line, circle {
                        color: var(--colors_neutral-3);
                    }
                }

                .active_favorite {
                    path {
                        fill: var(--colors_secondary-lightest);
                    }
                }

                .label {
                    color: var(--colors_neutral-3);
                }
            }


            .icon {
                height: 2rem;

                path, polyline, line, circle {
                    color: var(--colors_neutral-4);
                }
            }

            .active_favorite {
                path {
                    fill: var(--colors_primary);
                    stroke: var(--colors_primary)
                }
            }

            .label {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                color: var(--colors_neutral-4);
            }
        }
    }
}