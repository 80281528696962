.background {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: flex-end;
    align-items: center;

    @keyframes animateIn {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    .detail {
        background-color: white;
        height: 100%;
        transform: translateX(100%);
        position: relative;
        margin-left: calc(55px / 2);
        animation: animateIn .6s var(--transitions_easeoutquint) forwards;
    }

    @keyframes animateOut {
        0% {
            transform: translateX(0%);
            visibility: visible;
            opacity: 1;
        }

        100% {
            transform: translateX(100%);
            visibility: hidden;
            opacity: 0;
        }
    }

    .close_detail {
        animation: animateOut .6s var(--transitions_easeoutquint) forwards;
    }

    .close_button {
        position: absolute;
        top: 50%;
        left: 0;
        width: 55px;
        height: 55px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
}
