

.loginform {
    width: 100%;
    display: flex;
    justify-content: center;    
    
    .loginform_wrapper {
        max-width: 35.63rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.3rem;

        div {
            transition-duration: 0s;
        }
    }

    .input {
        .label_wrapper {
            display: flex;
            justify-content: space-between;
        }
    
        .label {
            font-size: var(--fontsize_s);
            font-weight: 500;
            color: var(--colors_neutral-1);
            margin-bottom: .3rem;
        }

        .label_password {
            text-decoration: underline;
            cursor: pointer;
            color: var(--colors_neutral-4);
            transition: .3s var(--transitions_easeoutquint);;
            &:hover {
                color: var(--colors_neutral-3)
            }
        }
    }
    
    .content {
        .emoji {
            margin-bottom: 1rem;
            width: 40px;
            height: 40px;
        }
    
        .title {
            margin-bottom: .3rem;
        }
    
        .description {
            color: var(--colors_neutral-3)
        }
    }
}


.n_login {
    position: relative;

    .l_form {
        transition: .6s var(--transitions_easeinoutexpo) all;
        position: absolute;
        left: 2rem;
        top: 0;
        height: 100vh;
        width: calc(50vw - 2rem);
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;

        .logo_ct {
            margin-top: 2rem;
            width: 100%;
            display: flex;
            justify-content: center;

            .logo_wrapper {
                max-width: 35.63rem;
                width: 100%;
                display: flex;
                align-items: center;

                .logo {
                    height: 3.5rem;
                }
            }
        }
        
        .ddl_ct {
            margin-bottom: 2rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            .ddl_wrapper {
                max-width: 35.63rem;
                width: 100%;
                display: flex;
            }
        }
        
    }

    .r_image {
        position: absolute;
        right: 0;
        height: 100vh;
        top: 0;
        width: 50vw;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .6s var(--transitions_easeinoutexpo) all;

        .bg_box {
            overflow: hidden;
            background: linear-gradient(60deg, rgba(34,116,165,1) 0%, rgba(61,158,189,1) 100%);
            width: calc(100% - 4rem);
            height: calc(100% - 4rem);
            border-radius: 2rem;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            position: relative;

            .phones {
                width: 100%;
                max-height: 70%;
            }

            @keyframes animateOpacity {
                0% {
                    opacity: 1;
                    transform: scale(1);
                }

                50% {
                    opacity: .5;
                    transform: scale(.8);
                }

                100% {
                    opacity: 1;
                    transform: scale(1);
                }
            }

            .dts {
                position: absolute;
            }

            .dot {
                top: 2rem;
                animation: animateOpacity 5s ease infinite;
            }

            .bubbles {
                right: 4rem;
                top: 20%;
                animation: animateOpacity 6s ease infinite;
            }

            .dots {
                left: 4rem;
                bottom: 20%;
                animation: animateOpacity 7s ease infinite;
                animation-delay: 1s;
            }

            .r_content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
                width: 80%;
                
                .r_title {
                    font-weight: 600;
                    text-align: center;
                    color: white;
                    font-size: 1.2rem;
                }

                .r_description {
                    text-align: center;
                    color: white;
                }
            }
        }
    }

    .fp_img {
        right: 100vw;
        transform: translateX(100%);
    }

    .fp_frm {
        left: calc(100% - 2rem);
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 1050px) {
    .n_login .r_image .bg_box .phones {
        width: 10rem;
    }
}

@media screen and (max-width: 768px) {
    .n_login {
        .l_form {
            width: calc(100vw - 2rem);
            left: 1rem;
        }
        .r_image {
            display: none;
        }

        .fp_frm {
            transform: none;
        }
    }
}

@media screen and (max-width: 576px) {
    
}