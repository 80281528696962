.notification {
    position: fixed;
    z-index: 303;
    top: 1rem;
    left: calc(100vw - 1rem);
    background-color: white;
    display: flex;
    border-radius: 6px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    width: max-content;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;

    .border {
        width: 3px;
        height: 100%;
        position: absolute;
        left:0;
        background-color: var(--colors_primary);
    }

    .success {
        background-color: var(--alert_successIcon);
    }

    .warning {
        background-color: var(--alert_warningIcon);
    }

    .error {
        background-color: var(--alert_errorIcon);
    }

    .pending {
        background-color: var(--alert_infoIcon);
    }

    .status {
        margin-left: calc(1rem + 3px);
        padding-top: 1rem;

        .symbol {
            width: 16px;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        .symbol_loader {
            border: 2px solid #f3f3f3; /* Light grey */
            border-top: 2px solid var(--alert_infoIcon); /* Blue */
            border-radius: 50%;
            width: 16px;
            height: 16px;
            animation: spin 2s linear infinite;
        }
    }

    .content {
        margin-left: 1rem;
        padding: 1rem 0;

        .title {
            font-weight: 700;
            color: var(--colors_neutral-2);
            margin-bottom: .2rem;
            display: block;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        .description {
            color: var(--colors_neutral-2); 
            font-size: 1rem;
            max-width: 20rem;
        }

        .hour {
            font-size: 13px;
            text-transform: uppercase;
            color: var(--colors_neutral-3);
        }
    }

    .close {
        margin-left: 1rem;
        padding-top: .5rem;
        padding-right: .5rem;

        .button {
            height: 2rem;
            width: 2rem;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                .icon {
                    opacity: 1;
                }
            }

            .icon {
                height: 1rem;
                opacity: .5;
            }
        }
    }
}

@keyframes animateIn {
    from {
        visibility: hidden;
        opacity: 0;
        transform: translateX(0%);
    }

    to {
        visibility: visible;
        opacity: 1;
        transform: translateX(-100%);
    }
}

@keyframes animateOut {
    from {
        visibility: visible;
        opacity: 1;
        transform: translateX(-100%);
    }

    to {
        visibility: hidden;
        opacity: 0;
        transform: translateX(0%);
    }
}

.notification_in {
    animation: animateIn var(--transitions_easeoutquint) .6s forwards;
}

.notification_out {
    animation: animateOut var(--transitions_easeoutquint) .6s forwards;
}