.dossiers {
    padding: 0rem;
    position: relative;

    .bg_image {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        background-color: gainsboro;
        width: 100%;
        height: 20vh;
        background-image: url("../../assets/images/dashboard_image.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .top_content {
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .title {
                color: white;
            }

            .description {
                color: white;
                text-transform: uppercase;
            }
        }

        .top_container {
            background-color: white;
            padding: 1rem;
            border-radius: var(--borderradius_m);
            width: 100%;

            .items {
                margin-top: 1rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                overflow-y: auto;

                .item {
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;

                    &:hover {
                        .placeholder_box {
                            box-shadow: 0 0 5px rgba(11, 32, 153, 0.1);
                            transform: scale(1.1);
                        }
                    }

                    .placeholder {
                        background-color: #F4F6F6;
                        height: 7rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: var(--borderradius_m);
                        margin-bottom: .5rem;

                        .placeholder_box {
                            width: 50px;
                            height: 50px;
                            background-color: #08688e2a;
                            border-radius: var(--borderradius_s);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: .2s ease all;
                            
                            svg {
                                height: 24px;
                                fill: var(--colors_primary);
                            }
                        }
                    }

                    .file_content {
                        display: flex;
                        flex-direction: column;

                        .file_name {
                            max-width: 15rem;
                            width: 15rem;
                            font-weight: 600;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .file_date {
                            font-size: 15px;
                            color: var(--colors_neutral-4);
                        }
                    }
                }
            }
        }

        .result {
            margin-top: 2rem;

            .options {
                margin-top: .5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .search_icon {
                    height: 1.2rem;
                }

                .action {
                    display: flex;
                    align-items: center;
                    gap: 0rem;

                    .action_btn {
                        width: 40px;
                        height: 40px;
                        background-color: transparent;
                        border-radius: 4px;
                        position: relative;

                        &:hover {
                            background-color: var(--colors_primary);

                            svg {
                                stroke:white;

                                path {
                                    stroke: white;
                                }
                            }
                        }

                        svg {
                            width: 1.2rem;

                            stroke: var(--colors_neutral-3);

                            path {
                                stroke: var(--colors_neutral-3);
                            }
                        }

                        .cta {
                            position: absolute;
                            top: 0;
                            right: calc(100% + 5px);
                            background-color: rgba(0, 0, 0, 0.6);
                            padding: 1rem;
                            border-radius: var(--borderradius_m);
                            backdrop-filter: blur(3px);

                            &::after {
                                content: " ";
                                position: absolute;
                                top: 50%;
                                left: 100%; /* To the right of the tooltip */
                                margin-top: -5px;
                                border-width: 5px;
                                border-style: solid;
                                border-color: transparent transparent transparent rgba(0, 0, 0, 0.6);
                            }

                            .text {
                                color: white;
                                font-size: 1rem;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }

            .list {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;
                gap: .5rem;

                .list_item {
                    background-color: white;
                    display: grid;
                    grid-template-columns: 4rem auto 10rem 10rem 2rem;
                    border-radius: var(--borderradius_s);
        
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

                        .icon {
                            transform: scale(1.1);
                        }
                    }

                    .icon {
                        padding: 1rem 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: .2s ease all;
                        transform: scale(1);
                        svg {
                            opacity: .4;                         
                        }
                    }

                    .value {
                        display: flex;
                        align-items: center;
                    }

                    .actions {
                        justify-content: flex-end;
                        padding-right: 1rem;
                        position: relative;

                        .action {
                            width: 30px;
                            height: 30px;
                            background-color: transparent;

                            &:hover {
                                svg {
                                    opacity: .8;
                                }
                            }

                            svg {
                                opacity: .4;
                            }
                        }

                        .dropdown {
                            z-index: 1;
                            position: absolute;
                            top: 60%;
                            background-color: white;
                            padding: 0.5rem;
                            border-radius: var(--borderradius_s);
                            transition: 0.3s ease all;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                            display: flex;
                            flex-direction: column;
                            gap: 0.25rem;
                            max-height: 20rem;
                            overflow: hidden;

                            .dropdown_option {
                                display: flex;
                                gap: 0.5rem;
                                align-items: center;
                                background-color: transparent;
                                padding: 0.5rem;
                                border-radius: var(--borderradius_s);

                                &:hover {
                                    background-color: var(--colors_neutral-6);
                                }

                                .option_icon {
                                    svg {
                                        width: 1.2rem;
                                        color: var(--colors_neutral-2);
                                    }
                                }

                                .option_label {
                                    font-size: 15px;
                                    font-weight: 500;
                                    color: var(--colors_neutral-2);
                                    line-height: 100%;
                                    text-align: left;
                                    white-space: nowrap;
                                }
                            }
                        }

                        .dropdown_hidden {
                            max-height: 0px;
                            overflow: hidden;
                            padding: 0 .5rem;
                        }
                    }

                    .item_info {
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding: 1rem 0;
                        overflow: hidden;
                        
                        .name {
                            font-weight: 600;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .amount {
                            font-size: 14px;
                            color: var(--colors_neutral-4)
                        }
                    }
                }
            }

            .grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;

                .list_item {
                    padding: 1rem;
                    display: grid;
                    grid-template-columns: 1.5rem auto 6rem;
                    width: 100%;
                    gap: 1rem;
                    

                    .item_info {
                        .name {
                            white-space: normal;
                        }
                        .amount {
                            display: none;
                        }
                    }

                    .item_type {
                        display: none;
                    }

                    .item_date {
                        padding: 1rem 0;
                        font-size: 14px;
                        text-align: center;
                        line-height: 100%;
                        p {
                            color: var(--colors_neutral-3)
                        }
                    }

                    .actions {
                        display: none;
                    }
                }
            }

            .empty {
                margin-top: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2rem;

                .image {
                    width: 200px;
                }
            }
        }
    }
}

@media screen and (max-width: 1040px) {
    .dossiers .content .result .grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 900px) {
    .dossiers .content .result .list .list_item {
        grid-template-columns: 4rem auto 2rem;
        .item_type {
            display: none;
        }

        .item_date {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .dossiers .content .result .grid {
        grid-template-columns: 1fr;

        .list_item {
            width: 100%;
        }
    }
}