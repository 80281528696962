.portal {
    width: 100%;
    height: 100%;
    background-color: #F8F9FC;
    display: flex;

    .content {
        margin-left: 0;
        width: 100%;
        max-height: 100vh;
        overflow: auto;
    }
}

.admin_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    gap: 1rem;
    max-width: 30rem;
    min-width: 15rem;
    width: 100%;
    margin: 0 auto;
}

@media screen and (max-width: 641px) {
    .portal {
        .content {
            margin-left: unset;
            margin-top: 0px
        }
    }
}