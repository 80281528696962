.filepreview {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 51;
    width: 100vw;
    height: 100vh;

    .header {
        height: 100px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;

        .left {
            display: flex;
            align-items: center;
            height: 100%;

            .close {
                width: 100px;
                display: flex;
                justify-content: center;
    
                .close_btn {
                    width: 30px;
                    height: 30px;
                    background-color: var(--colors_neutral-6);
                    border-radius: 50%;

                    &:hover {
                        background-color: var(--colors_neutral-5);
                        svg {
                            color: var(--colors_neutral-1)
                        }
                    }
        
                    svg {
                        color: var(--colors_neutral-2);
                        width: 1.2rem;
                    }
                }
            }
    
            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
    
                .file_info {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin-top: 1rem;
    
                    .file_name {
                        font-weight: 600;
                        max-width: 30rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
    
                    .like_btn {
                        width: 30px;
                        height: 30px;
    
                        &:hover {
                            svg {
                                fill: var(--colors_primary);
                                color: var(--colors_primary);
                            }
                        }
                        
                        svg {
                            height: 1.2rem;
                            color: var(--colors_neutral-3)
                        }
                    }

                    .active_favorite {
                        svg {
                            fill: var(--colors_primary);
                            color: var(--colors_primary);
                        }

                        &:hover {
                            svg {
                                fill: var(--colors_secondary-lightest);
                            }
                        }
                    }
                }
            }

            .toolbar {
                display: flex;
                gap: 1rem;
                margin-bottom: .5rem;
                transform: translateX(-.5rem);

                .menu {
                    position: relative;

                    .menu_item {
                        font-size: 15px;
                        text-transform: uppercase;
                        color: var(--colors_neutral-3);
                        font-weight: 500;
                        background-color: transparent;
                        padding: .5rem;
                        border-radius: var(--borderradius_s);

                        &:hover {
                            background-color: var(--colors_neutral-6);
                        }
                    }

                    .menu_list {
                        position: absolute;
                        top: 100%;
                        background-color: white;
                        padding: .5rem;
                        border-radius: var(--borderradius_s);
                        transition: .3s ease all;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                        display: flex;
                        flex-direction: column;
                        gap: .25rem;
                        max-height: 20rem;
                        overflow: hidden;

                        .menu_list_option {
                            display: flex;
                            gap: .5rem;
                            align-items: center;
                            background-color: transparent;
                            padding: .5rem;
                            border-radius: var(--borderradius_s);

                            &:hover {
                                background-color: var(--colors_neutral-6);
                            }

                            .icon {
                                svg {
                                    width: 1.2rem;
                                    color: var(--colors_neutral-2)
                                }
                            }

                            .label {
                                font-size: 15px;
                                font-weight: 500;
                                color: var(--colors_neutral-2);
                                line-height: 100%;
                                text-align: left;
                                white-space: nowrap;
                            }
                        }
                    }

                    .menu_list_hidden {
                        max-height: 0px;
                        padding: 0 .5rem;
                        overflow: hidden;
                    }
                }
            }
        }
    
        .right {
            padding-right: 1rem;
        }
    }

    .mobile_tabs_btn {
        display: none;
    }

    .tabs {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 1rem;
        padding-left: .5rem;
        background-color: white;
        border-top-right-radius: var(--borderradius_l);
        border-bottom-right-radius: var(--borderradius_l);
        transition: .2s ease all;

        display: flex;
        flex-direction: column;
        gap: .5rem;

        .tab {
            padding: .75rem .5rem;
            position: relative;
            .tab_label {
                font-family: var(--fonts_primary);
                font-size: 1rem;
            }

            &:hover:not(.tab_active) {
                background-color: var(--colors_neutral-6);
                border-top-right-radius: 5rem;
                border-bottom-right-radius: 5rem;

                &::after {
                    content: '';
                    width: 2px;
                    height: 100%;
                    background-color: var(--colors_neutral-5);
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }

        .tab_active {
            &::after {
                content: '';
                width: 2px;
                height: 100%;
                background-color: var(--colors_primary);
                position: absolute;
                left: 0;
                top: 0;
            }

            .tab_label {
                font-weight: 600;
            }
        }
    }

    .preview {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;

        .container {
            border-radius: var(--borderradius_l);
            margin: 5rem 1rem;
            padding: 1rem;
            background-color: white;
            overflow-y: auto;
            width: 80vw;
            min-width: 300px;
            max-width: 800px;

            .page {
                border: 1px solid black;
            }

            .no_preview {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: .5rem;
                margin-top: 2rem;

                .title {
                    margin-top: 1rem;
                    font-weight: 600;
                    color: var(--colors_neutral-1);
                }

                .description {
                    color: var(--colors_neutral-3);
                    text-align: center;
                    max-width: 300px;
                    margin-bottom: 1rem;
                }
            }

            .email_preview {
                padding: 1rem;

                .preview_header {
                    background-color: red;
                }

                ul {
                    list-style-position: inside;
                }
            }
        }
    }
}

.container {
    width: 80vw;
    max-width: 800px;
    position: relative;
    height: 100%;
}

@media screen and (max-width: 1150px) {
    .mobile_tabs_btn {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 100%;
        transform: translateY(calc(-100% - 1rem));
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
        left: 1rem;
        z-index: 1;
        background-color: white;

        svg {
            width: 1.2rem;
        }
    }

    .tabs {
        left: 1rem !important;
        top: calc(100% - 40px - 2rem) !important;
        transform: translateY(-100%) !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        border-radius: var(--borderradius_l);
    }

    .hide_tabs {
        overflow: hidden;
        max-height: 0px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .filepreview .header {
        .close {
            width: 50px !important;
        }

        .content {

            .file_name {
                max-width: 50vw !important;
            }
        }
        .right {
            display: none;
        }       
    }
}

@media screen and (max-width: 641px) {
    .mobile_tabs_btn {
        transform: translateY(calc(-100% - 6rem));
    }

    .tabs {
        top: calc(100% - 40px - 2rem - 5rem) !important;
    }
}