.dashboard {
    padding: 0rem;
    position: relative;

    .bg_image {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        background-color: gainsboro;
        width: 100%;
        height: 20vh;
        background-image: url("../../assets/images/dashboard_image.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        padding: 4rem;
        width: 100%;

        .top_content {
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .title {
                color: white;
            }

            .description {
                color: white;
                text-transform: uppercase;
            }
        }

        .top_container {
            background-color: white;
            padding: 1rem;
            border-radius: var(--borderradius_m);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            &:hover {
                box-shadow: 0 0 5px #0000001c;
            }
            
            .search_field {
                display: flex;
                align-items: center;
                border-radius: 5rem;
                max-width: 600px;
                width: 100%;

                &:has(.search_input:focus){
                    outline: 1px solid var(--colors_primary);
                }

                .search_icon_ct {
                    padding: 0 .5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .search_icon {
                    width: 1.2rem;
                }

                .search_input {
                    border-top-right-radius: 5rem;
                    border-bottom-right-radius: 5rem;
                    background-color: transparent;
                    border: none;
                    font-size: 1rem;
                    width: 100%;
                    padding: .75rem 0;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .items {
                display: flex;
                align-items: center;
                gap: 2rem;
                overflow-y: auto;

                .item {
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;

                    &:hover {
                        .file_icon {
                            background-color: #eaecec;
                            border: 1px solid var(--colors_neutral-5)
                        }
                    }

                    .file_icon {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        background-color: var(--colors_neutral-6);
                        border-color: var(--colors_primary);
                        margin: 0 auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        transition: .2s ease all;
                    }

                    .file_name {
                        max-width: 7rem;
                        font-weight: 600;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .received_docs {
            .items {
                align-items: center;
                display: flex;
                gap: 1rem;
                margin-top: 1rem;
                overflow-y: auto;
                
                .item {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;

                    &:hover {
                        .doc_placeholder_img {
                            box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1);
                        }
                    }

                    .doc_placeholder {
                        background-color: var(--colors_neutral-5);;
                        border-radius: var(--borderradius_m);
                        height: 7rem;
                        margin-bottom: 0.5rem;
                        position: relative;
                        transition: all .2s ease;

                        .doc_type_icon {
                            bottom: 83px;
                            left: calc(50% - 60px);
                            position: absolute;
                            -webkit-transform: translateY(100%);
                            transform: translateY(100%);
                            z-index: 1;
                        }

                        .doc_placeholder_img {
                            bottom: 0;
                            left: 50%;
                            position: absolute;
                            -webkit-transform: translateX(-50%);
                            transform: translateX(-50%);
                        }
                    }

                    .doc_title {
                        align-items: center;
                        display: flex;
                        font-weight: 600;
                        gap: 0.5rem;
                        max-width: 15rem;
                        width: 15rem;

                        p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .doc_name {
                        color: var(--colors_neutral-4);
                        font-size: 15px;
                    }
                }
            }
        }

        .grid {
            margin-top: 2rem;
            // width: 100%;
            // display: flex;
            // max-width:100%;

            // margin-top: 2rem;
            // width: 100%;
            // display: flex;
            // gap: 2rem;
            // flex-wrap: wrap;

            .favorites {
                .items {
                    margin-top: 1rem;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1rem;
                    max-height: 30rem;
                    overflow-y: auto;

                    .item {
                        min-width: 7rem;
                        width: calc(50% - 1rem);
                        max-width: 15rem;
                        cursor: pointer;

                        &:hover {
                            .doc_placeholder {
                                background-color: #F4F6F6;
                                border: 1px solid var(--colors_neutral-5)
                            }     
                        }

                        .doc_placeholder {
                            background-color: white;
                            height: 7rem;
                            border-radius: var(--borderradius_m);
                            border-color: var(--colors_primary);
                            margin-bottom: .5rem;
                            position: relative;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            transition: .2s ease all;

                            .like_btn {
                                position: absolute;
                                right: 10px;
                                top: 10px;

                                &:hover {
                                    svg {
                                        fill: var(--colors_secondary-lightest);
                                    }
                                }

                                svg {
                                    fill: var(--colors_secondary-lighter);
                                }
                            }

                            .doc_type_icon {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }

                        .doc_title {
                            display: flex;
                            align-items: center;
                            gap: .5rem;
                            max-width: 15rem;    
                            font-weight: 600;    
    
                            p {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .doc_icon_svg {
                                min-width: 20px;
                            }

                        .doc_date {
                            font-size: 15px;
                            color: var(--colors_neutral-4);
                        }
                    }
                }

                .empty_favorites {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    padding: 1rem;
                    border-radius: var(--borderradius_m);
                    width: 100%;

                    .image {
                        width: 10rem;
                        margin-bottom: 1rem;
                    }

                    .title {
                        font-weight: bold;
                        color: var(--colors_neutral-1);
                        font-size: var(--fontsize_l);
                    }

                    .description {
                        max-width: 300px;
                        text-align: center;
                        color: var(--colors_neutral-3);
                        font-size: var(--fontsize_s);
                    }
                }
            }

            .search {
                .container {
                    margin-top: 1rem;
                    background-color: white;
                    padding: 1rem;
                    border-radius: var(--borderradius_m);

                    .search_input {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 1rem;
                    }

                    .subtitle {
                        margin: 1rem 0 .5rem 0;
                        font-weight: 600;
                    }

                    .items {
                        display: flex;
                        align-items: center;
                        gap: 2rem;
                        overflow-y: auto;

                        .item {
                            display: flex;
                            flex-direction: column;
                            cursor: pointer;

                            &:hover {
                                .file_icon {
                                    background-color: #eaecec;
                                    border: 1px solid var(--colors_neutral-5)
                                }
                            }

                            .file_icon {
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;
                                background-color: var(--colors_neutral-6);
                                border-color: var(--colors_primary);
                                margin: 0 auto;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                overflow: hidden;
                                transition: .2s ease all;
                            }

                            .file_name {
                                max-width: 7rem;
                                font-weight: 600;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }

            .actions {

                .activities {
                    margin-top: 1rem;
                    background-color: white;
                    padding: 1rem;
                    border-radius: var(--borderradius_m);
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                .activity {
                    display: flex;
                    gap: .5rem;
                    border: 1px solid var(--colors_neutral-5);
                    //background-color: #F4F6F6;
                    padding: 1rem;
                    border-radius: var(--borderradius_m);

                    .icon {
                        min-width: 40px;
                        min-height: 40px;
                        max-width: 40px;
                        max-height: 40px;
                        background-color: #F4F6F6;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: var(--borderradius_m);

                        svg {
                            width: 1.4rem;
                            fill: var(--colors_neutral-2)
                        }
                    }

                    .activity_data {
                        display: flex;
                        flex-direction: column;
                        gap: .25rem;
                    }

                    .deadline {
                        color: var(--colors_neutral-3);
                        display: flex;
                        align-items: center;
                        gap: .25rem;
                        font-size: 14px;

                        span {
                            display: flex;
                            align-items: center;

                            svg {
                                width: 1rem;
                            }
                        }

                        
                    }

                    .title {
                        font-weight: 600;
                    }

                    .description {
                        font-size: 15px;
                        color: var(--colors_neutral-2)
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .dashboard {
        .content {
            .grid {
                flex-direction: column-reverse;

                .favorites {
                    width: 100%;
                }

                .search {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 641px) {
    .dashboard {
        padding: 0rem;

        .content {
            padding: 1rem;
        }
    }
}