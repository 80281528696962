html {
  transition: color 300ms, background-color 300ms;
}

html[data-theme='dark-mode']{
  filter: invert(1) hue-rotate(180deg);
}

html[data-theme='dark-mode'] img{
  filter: invert(1) hue-rotate(180deg);
}

body {
  overflow: overlay;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--fonts_primary);
}

*::-webkit-scrollbar {
  background-color:#fff;
  width:16px
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color:#fff
}
*::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid #fff
}
*::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {display:none}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-gutter: stable both-edges;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  height: 100%;
}

#root {
  position: relative;
  height: 100%;
}


h1, h2, h3, h4, p {
  margin: 0;
  width: fit-content;
  color: var(--colors_secondary-darkest);
}

h1 {
  font-weight: 700;
  font-size: 25px;
}

[class*=ResultsAttribution] {
  display: none;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrSelected, .rdrEndEdge, .rdrDay, .rdrInRange, .rdrDayActive, .rdrStartEdge, .rdrDayStartPreview{
  color: var(--datepicker_calendarAccent) !important;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  border-color: var(--datepicker_calendarAccent) !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: var(--datepicker_calendarAccent) !important;
}

.rdrDayHovered {
  border-color: var(--datepicker_calendarAccent) !important;
}

.rdrDateDisplayItemActive{
  border-color:  var(--datepicker_calendarAccent) !important;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  content: '';
  border-color: var(--datepicker_calendarAccent) !important
}

.rdrMonth {
  padding: 1.666em 0.833em 1.666em 0.833em !important;
  background-color: var(--datepicker_calendarFill);
  border-bottom-left-radius: var(--borderradius_m);
}

.rdrMonthAndYearWrapper {
  display: none !important;
}

.rdrDayNumber span {
  color: var(--datepicker_calendarDayText) !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: var(--datepicker_calendarSelectedText) !important;
}

.rdrDayPassive .rdrDayNumber span {
  color: var(--datepicker_calendarPassiveText) !important;
}

.rdrWeekDay {
  color: var(--datepicker_calendarWeekdayText) !important;
  text-transform: capitalize;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.react-pdf__Page {
  border: 1px solid rgb(238, 238, 238);
  border-radius: var(--borderradius_m);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.textWidgetAnnotation {
  display: none !important;
}