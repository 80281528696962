.table_wrapper {
    overflow: auto;
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.th_item {
    padding: .5rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .9rem;
    color: var(--colors_neutral-4);
    border-bottom: 1px solid var(--colors_neutral-5);
    text-align: left;
    cursor: pointer;
    background-color: var(--colors_neutral-6);
    transition: all var(--transitions_fast) var(--transitions_easeoutexpo);
    &:hover {
        color: var(--colors_neutral-3)
    }
}

.td_item {
    padding: .5rem;
    background-color: white;
    font-weight: 500;

    &::first-letter {
        text-transform: uppercase;
    }
}

.th {
    background-color: var(--colors_neutral-6);

    .th_item:first-child {
        position: sticky;
        left: 0;
    }
}

.table_inner_wrapper {
    width: 0;
    flex: 1;
}

.tr {
    min-height: 48px;
    height: 48px;
    cursor: pointer;

    .td_item:first-child {
        position: sticky;
        left: 0;
    }

    &:not(:last-child){
        border-bottom: 1px solid var(--colors_neutral-5);
    }

    &:hover:not(.selected_tr) {
        .td_item {
            background-color: var(--colors_secondary-lightest);
        }
    }
}

.selected_tr {
    color: white;
    .td_item {
        background-color: var(--colors_primary);
    }
}