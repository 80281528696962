.title {
    color: var(--colors_neutral-1);
    margin-bottom: .25rem;
}

.description {
    color: var(--colors_neutral-2);
    font-size: .95rem;
}

.subtitle {
    color: var(--colors_neutral-1);
    font-size: 1.2rem;
}

.detail {
    padding: 2rem;
    min-width: 40rem;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .add_item {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1.5rem 0;
        padding: 1.5rem 0;
        border-top: 1px solid var(--colors_neutral-5);
        border-bottom: 1px solid var(--colors_neutral-5);

        .title {
            font-weight: 600;
        }
    }

    .input_container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-height: 32rem;
        overflow-y: auto;

        .input {
            .input_header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .label {
                    font-weight: 500;
                    margin-bottom: .3rem;
                }

                .delete_button {
                    width: 25px;
                    height: 25px;
                    
                    &:hover {
                        svg {
                            color: var(--colors_neutral-1);
                            stroke-width: 2;
                        }
                    }

                    svg {
                        height: 1rem;
                        color: var(--colors_neutral-3);
                        transition: .2s ease all;
                    }
                }
            }
        }

        
    }
}

.translations {
    padding: 2rem;

    .cards {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: 2rem;

        .card {
            width: 15rem;
            background-color: white;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.144);
            transition: .6s --transitions_easeoutquint all;

            &:hover {
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.144);
            }

            .card_color {
                width: 100%;
                height: 3rem;
            }

            .card_info {
                padding: .5rem;

                .card_title {
                    font-weight: 600;
                }

                .card_description {
                    font-size: .9rem;
                    color: var(--colors_neutral-4);
                }
            }
        }
    }
}