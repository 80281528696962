.header {
    width: 100%;
    background-color: white;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1rem;

    .today {
        text-transform: capitalize;
        color: var(--colors_neutral-3);
        display: flex;
        align-items: center;
        gap: .5rem;

        .icon {
            display: flex;
            align-items: center;

            path {
                stroke: var(--colors_neutral-3);
            }
        }
    }

    .nightmode_button {
        svg {
            height: 18px;
            stroke: var(--colors_neutral-3);

            path {
                stroke: var(--colors_neutral-3);
            }
        }

        &:hover {
            svg {
                path {
                    fill: rgb(241, 186, 34);
                }

                circle {
                    fill: rgb(241, 186, 34);
                }
            } 
        }
    }
}

@media screen and (max-width: 641px) {
    .header {
        display: none;
    }
}