.warning {
    background-color: white;
    border-radius: var(--borderradius_l);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;

        &:hover {
            svg {
                color: var(--colors_neutral-1)
            }
        }

        svg {
            color: var(--colors_neutral-3);
            width: 1rem;
        }
    }

    .image {
        width: 7rem;
        padding: 2rem 0;
        transform: translateX(.8rem);
    }

    .body {
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
    }

    .title {
        font-weight: 600;
        color: var(--colors_neutral-1)
    }

    .description {
        color: var(--colors_neutral-3);
        text-align: center;
        max-width: 300px;
    }

    .button {
        background-color: var(--alert_errorIcon);
        color: white;
        padding: .75rem;
        font-size: .9rem;
        font-weight: 500;
        border-radius: 10rem;
    }
}