// .navigation {
//     position: fixed;
//     z-index: 1;
//     left: 0;
//     top: 0;
//     height: 100vh;
//     background-color: white;
//     width: 100px;
//     transition: .6s var(--transitions_easeoutquint) all;
//     transition-delay: .5s;

//     &:hover {
//         width: 300px;

//         .indicator {
//             transform: rotate(180deg);
//         }

//         .header {
//             .boxlogo_letters_wrapper {
//                 opacity: 1;
//             }
//         }
//     }
// }

// .navigation_content {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

// }

// .indicator {
//     position: absolute;
//     left: 100%;
//     transform: translateX(-50%);
//     top: 50px;
//     background-color: var(--colors_primary);
//     width: 20px;
//     height: 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 50%;
//     box-shadow: 0 0 0 10px #F8F9FC;
// }

// .header {
//     display: flex;
//     align-items: center;
//     gap: .5rem;
//     margin-top: 60px;
//     max-height: 35px;
//     position: relative;

//     .boxlogo_wrapper {
//         position: absolute;
//         width: 100px;
//         display: flex;
//         justify-content: center;
//         right: 0;

//         image {
//             width: 3rem;
//         }
//     }

//     .boxlogo_letters_wrapper {
//         opacity: 0;
//         position: absolute;
//         right: 100px;
//         display: flex;
//         align-items: center;
//         height: 35px;
//         transition: .6s var(--transitions_easeoutquint) all;
//         transition-delay: .1s;

//         img {
//             height: 100%;
//         }
//     }
// }

// .body {
//     margin-top: 50px;
//     display: flex;
//     flex-direction: column;
//     gap: .5rem;
//     padding: 0 1rem;

//     .navigation_item {    
//         padding: .85rem 0;
//         border-radius: 5px;
//         background-color: transparent;
//         position: relative;
//         cursor: pointer;

//         .navigation_item_wrapper {
//             display: flex;
//             align-items: center;
//             overflow: hidden;
//         }

//         .icon {
//             min-width: calc(100px - 2rem);
//             max-width: calc(100px - 2rem);
//             display: flex;
//             justify-content: center;

//             path {
//                 fill: #A3AEBB;
//             }
//         }

//         .label {
//             font-size: 1rem;
//             font-weight: 500;
//             color: #A3AEBB;
//             white-space: nowrap;
//         }

//         &:not(.navigation_item_active):hover {
//             background-color: #f5f7f8;

//             .icon {
//                 path {
//                     fill: #7e8a97;
//                 }
//             }

//             .label {
//                 color: #7e8a97;
//             }
//         }
//     }

//     .navigation_item_active {
//         background-color: #EAEEF0;

//         .icon {
//             path {
//                 fill: var(--colors_primary)
//             }
//         }

//         .label {
//             color: var(--colors_neutral-1);
//         }

//         &::after {
//             content: "";
//             width: 5px;
//             height: 100%;
//             background-color: var(--colors_primary);
//             position: absolute;
//             left: -1rem;
//             top: 0;
//             border-top-right-radius: 2rem;
//             border-bottom-right-radius: 2rem;
//         }
//     }
    
// }

// .bottom {
//     display: flex;
//     justify-content: space-between;
    
//     margin-bottom: 2rem;
//     position: relative;

//     .bottom_wrapper {
//         overflow: hidden;
//         display: flex;
//         justify-content: space-between;
//         width: 100%;
//     }

//     .profile_content {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         .profile_picture_wrapper {
//             min-width: 100px;
//             display: flex;
//             justify-content: center;

//             .profile_picture {
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 width: 43px;
//                 height: 43px;
//                 border-radius: 50%;
//                 background-color: var(--colors_primary);
                
//                 .profile_letter {
//                     color: white;
//                     font-weight: 600;
//                     font-size: 1.25rem;
//                     user-select: none;
//                 }
//             }
//         }

//         .profile_info {
//             display: flex;
//             flex-direction: column;

//             .profile_name {
//                 white-space: nowrap;
//                 font-size: 1rem;
//                 font-weight: 500;
//                 color: var(--colors_neutral-1);
//             }

//             .profile_role {
//                 white-space: nowrap;
//                 font-size: .85rem;
//                 color: var(--colors_neutral-3)
//             }
//         }
//     }

//     .profile_action {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         background-color: transparent;
//         width: 40px;
//     }

//     .profile_actions {
//         opacity: 0;
//         visibility: hidden;
//         position: absolute;
//         right: 0;
//         bottom: 2rem;
//         transform: translateX(50%);
        
//         background-color: white;
//         padding: .5rem 0;
//         border-radius: 10px;
//         box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

//         .profile_action_item {
//             display: flex;
//             align-items: center;
//             gap: .5rem;
//             padding: .5rem;
//             cursor: pointer;

//             .icon {
//                 svg {
//                     width: 22px;
//                     height: 22px;
//                     stroke: #A3AEBB;
//                 }
//             }

//             .label {
//                 color: #A3AEBB
//             }

//             &:hover {
//                 background-color: #EAEEF0;
//                 .icon svg {
//                     stroke: #7e8a97;
//                 }

//                 .label {
//                     color: #7e8a97;
//                 }
//             }
//         }
//     }

//     @keyframes showProfileActions {
//         0% {
//             opacity: 0;
//         }

//         100% {
//             opacity: 1;
//         }
//     }

//     .profile_actions_show {
//         animation: showProfileActions .3s var(--transitions_easeoutquint) forwards;
//         opacity: 0;
//         visibility: visible;
//         transform-origin: center;
//     }
// }

// .mobile_menu {
//     display: none;
// }

@media screen and (max-width: 641px) {
    .navigation {
        width: 100%;
        height: 55px;
        overflow: hidden;
        transition-delay: 0s;

        &:hover {
            width: 100%;
        }

        .header {
            margin-top: calc(55px / 2);

            .boxlogo_wrapper {
                right: unset;
                left: 0;
            }

            .boxlogo_letters_wrapper {
                display: none;
            }
        }

        .indicator {
            display: none;
        }

        .body {
            margin-top: calc(55px / 2 + 1rem)
        }

        .bottom {
            .profile_actions {
                transform: unset;
            }
        }
    }

    .mobile_menu {
        display: flex;
        position: absolute;
        right: 1rem;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 21px;
        
        .mobile_menu_wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .line {
                height: 2px;
                width: 100%;
                background-color: var(--colors_neutral-2);
                border-radius: 10rem;
                transition: .3s var(--transitions_easeoutquint) all;
            }

            .l_one {
                position: absolute;
                top: 0;
                transform-origin: top left;
            }

            .l_two {
                top: 50%;
                transform: translateY(-1px);
            }

            .l_three {
                position: absolute;
                bottom: 0;
                transform-origin: bottom right;
            }
        }
    }

    .mobile_show_navigation {
        height: 100vh;

        .l_one {
            left: 5px;
            transform: rotate(45deg) !important;
        }

        .l_two {
            transform: rotate(-45deg) !important;
        }

        .l_three {
            width: 0px !important;
        }
    }
}

.nav {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);

    .ct {
        width: 100%;

        .top {
            margin-top: 2rem;
            margin-bottom: 3.5rem;
            display: flex;
            justify-content: center;

            .logo {
                height: 3.5rem;
            }
        }

        .items {
            
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            gap: 2rem;
            position: relative;

            .indicator {
                position: absolute;
                left: 2px;
                width: 2px;
                height: 4rem;
                border-radius: 1rem;
                background-color: var(--colors_primary);
                transition: .3s ease all;
            }

            .item {
                padding: .25rem 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: .5rem;
                cursor: pointer;

                svg {
                    width: 1.5rem;
                    
                    path {
                        stroke: var(--colors_neutral-4);
                    }
                }

                .label {
                    font-size: 13px;
                    text-transform: uppercase;
                    color: var(--colors_neutral-4);
                    font-weight: 500;
                }
            }

            .item_active {
                svg {
                    path {
                        stroke: var(--colors_primary);
                    }
                }

                .label {
                    color: var(--colors_neutral-1)
                }
            }
        }
    }

    .ft {
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        position: relative;

        .profile {
            display: flex;
            flex-direction: row;
            gap: .25rem;
            align-items: center;
            background-color: var(--colors_neutral-6);
            cursor: pointer;
            padding: .15rem;
            width: fit-content;
            border-radius: 2rem;
            transition: .3s ease all;

            &:hover {
                padding-right: .5rem;

                .triangle {
                    fill: var(--colors_neutral-3);
                }
            }

            .picture {
                width: 25px;
                height: 25px;
                background-color: var(--colors_primary);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: white;
                font-weight: 500;
            }

            .name {
                color: var(--colors_neutral-2);
                font-weight: 500;
                max-width: 4rem;
                overflow: hidden;
            }

            .triangle {
                width: 12px;
                height: 12px;
                transform: rotate(90deg);
                fill: var(--colors_neutral-4);
                transition: .3s ease all;
            }
        }

        .menu {
            max-width: 15rem;
            position: absolute;
            z-index: 1;
            right: -.5rem;
            bottom: 0;
            transform: translateX(100%);
            background-color: white;
            padding: .25rem;
            border-radius: 4px;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
            transition: .3s ease all;

            .m_item {
                display: flex;
                align-items: center;
                gap: .5rem;
                padding: .25rem;
                cursor: pointer;
                border-radius: 4px;
                
                &:hover {
                    background-color: var(--colors_neutral-6);
                    .icon {
                        transform: translateX(2px);
                    }
                }

                .icon {
                    color: var(--colors_neutral-3);
                    width: 1.3rem;
                    transition: .15s ease all;
                }
            }
        }

        .menu_hidden {
            max-width: 0px;
            overflow: hidden;
            padding-right: 0;
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 641px) {
    .nav {
        position: fixed;
        z-index: 99;
        width: 100vw;
        overflow-y: auto;
        top: 100vh;
        height: 80px;
        transform: translateY(-100%);
        flex-direction: row;
        box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.05);

        .ct {
            display: flex;
            flex-direction: row;
            margin-left: 1rem;
            margin-right: 1rem;
            
            .top {
                display: none;
                // margin-top: unset;
                // margin-bottom: unset;
                // align-items: center;
                // margin-left: 1rem;
                // margin-right: 1rem;

                .logo {
                    height: 2.5rem;
                }
            }

            .items {
                margin-top: unset;
                flex-direction: row;
                align-items: center;
                
                .indicator {
                    display: none;
                }
            }
        }

        .ft {
            display: none;
        }
        
    }
}