.background {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
        background-color: white;
        border-radius: 10px;
        margin: 0 1rem;
    }
}

.alert {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        background-color: var(--colors_neutral-6);
    }

    .title {
        font-weight: 500;
        font-size: 23px;
        margin-top: 1rem;
        margin-bottom: .5rem;
    }

    .description {
        color: var(--colors_neutral-3);

        .columnname {
            font-weight: 500;
            color: var(--colors_neutral-2)
        }
    }

    .actions {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.action {
    max-width: 600px;
    width: 60vw;
    min-width: 300px;
    max-height: 98vh;
    overflow-y: auto;

    .header {
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        border-bottom: 1px solid var(--colors_neutral-5);

        .title {
            font-size: 23px;
            font-weight: 500;
        }

        .description {
            font-size: 14px;
            color: var(--colors_neutral-4);
        }

        .close_button {
            width: 24px;
            height: 24px;
        }
    }

    .body {
        border-bottom: 1px solid var(--colors_neutral-5);
        padding: 2rem;
    }

    .footer {
        align-items: center;
        display: flex;
        padding: 2rem;
    }

    .flexend {
        display: flex;
        justify-content: flex-end;
    }

    .spacebetween {
        justify-content: space-between;
    }
}