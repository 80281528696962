.dashboard {
    padding: 0rem;
    position: relative;

    .bg_image {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        background-color: gainsboro;
        width: 100%;
        height: 20vh;
        background-image: url("../../assets/images/dashboard_image.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        padding: 4rem;
        width: 100%;

        .top_content {
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .title {
                color: white;
            }

            .description {
                color: white;
                text-transform: uppercase;
            }
        }

        .top_container {
            background-color: white;
            padding: 1rem;
            border-radius: var(--borderradius_m);
            width: 100%;

            .grid {
                margin-top: 1rem;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 1rem;

                .search_icon {
                    height: 1.2rem;
                }

                .dropdownlist div div {
                    padding: .75rem
                }

                .label {
                    font-size: .9rem;
                    font-weight: 600;
                    margin-bottom: .25rem;
                    color: var(--colors_neutral-1)
                }

                .doctypes {
                    margin-top: .5rem;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: .5rem;

                    .subtitle {
                        font-weight: 600;
                    }

                    .type_item {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        &:hover {
                            span img {
                                opacity: 1;
                            }
                        }

                        span {
                            display: flex;
                            align-items: center;

                            img {
                                opacity: .5;
                                width: 1rem;
                            }
                        }
                    }
                }
            }
        }

        .documents {
            margin-top: 2rem;

            .title {
                font-size: 20px;
            }

            .items {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .item {
                    display: grid;
                    grid-template-columns: auto calc(3 * 40px + 2 * 0.5rem);
                    background-color: white;
                    border-radius: var(--borderradius_m);

                    &:hover {
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

                        .tag {
                            background-color: var(--colors_neutral-4) !important;
                            color: white !important;
                            padding: .25rem .4rem !important;
                        }
                    }

                    .item_content {
                        padding: 1rem 0;
                        cursor: pointer;
                        display: grid;
                        grid-template-columns: 3.75rem auto;

                        .icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .item_text {
                            display: flex;
                            flex-direction: column;
                            gap: .5rem;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .filename {
                            font-weight: 600;
                            color: var(--colors_neutral-1);
                        }

                        .filedate {
                            color: var(--colors_neutral-4);
                            display: flex;
                            align-items: center;
                            gap: .25rem;

                            span {
                                display: flex;
                                align-items: center;
                                opacity: .4;
                            }
                        }

                        .tags {
                            display: flex;
                            flex-wrap: wrap;
                            gap: .5rem;

                            .tag {
                                background-color: transparent;
                                color: black;
                                padding: .25rem .4rem;
                                border-radius: var(--borderradius_s);
                                font-size: .9rem;
                                transition: .2s ease all;
                                border: 1px solid var(--colors_neutral-4);
                            }
                        }
                    }

                    .actions {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: .5rem;
                        padding-right: 1rem;

                        .action {
                            width: 35px;
                            height: 35px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover:not(.active_favorite) {
                                svg {
                                    path {
                                        stroke: var(--colors_neutral-2)
                                    }
                                }
                            }

                            svg {
                                width: 21px;
                                height: 21px;
                                
                                path {
                                    stroke-width: 1.5px;
                                    fill: transparent;
                                    stroke: var(--colors_neutral-3)
                                }  
                            }
                        }

                        .active_favorite {
                            svg {
                                path {
                                    fill: var(--colors_primary);
                                    stroke: var(--colors_primary)
                                }
                            }

                            &:hover {
                                svg {
                                    path {
                                        fill:var(--colors_secondary-lightest);
                                        stroke:var(--colors_neutral-3);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 1000px) {
    .dashboard {
        .content {
            .top_container .grid {
                grid-template-columns: 1fr;
            }
        }
    }
}

@media screen and (max-width: 641px) {
    .dashboard {
        padding: 0rem;

        .content {
            padding: 1rem;
        }
    }
}