.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    max-width: 100vw;
    width: 100%;
    overflow: hidden;

    .animation {
        width: 200px;
        height: 250px;
        transition: 4s linear all;
    }

    .animate_out {
        transform: translateX(60vw);
    }

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .title {
        margin-bottom: .5rem;
    }
}
