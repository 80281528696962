.searchdossiers {
    padding: 1rem;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .title {
            font-weight: 500;
            font-size: 20px;
        }

        .close {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                svg {
                    color: var(--colors_neutral-1)
                }
            }

            svg {
                width: 1.2rem;
                color: var(--colors_neutral-3)
            }
        }
    }

    .body {
        margin: 1rem 0;
        padding: 1rem 0;
        border-top: 1px solid var(--colors_neutral-5);
        border-bottom: 1px solid var(--colors_neutral-5);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;

        .label {
            font-size: .8rem;
            font-weight: 500;
        }

        .ddl div div:first-of-type {
            padding: .75rem;
        }
    }
}